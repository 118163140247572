















































































































































































































































import Confirm, { useConfirmation } from '@/components/confirmation/Confirmation.vue';
import JobDurationText from '@/components/job-duration-text/JobDurationText.vue';
import JobStatusIcon from '@/components/job-status-icon/JobStatusIcon.vue';
import DomainWorkspaceToolbar from '@/components/navigation/DomainWorkspaceToolbar.vue';
import RefreshButton from '@/components/navigation/RefreshButton.vue';
import HelpTooltipDialogs, { useHelpTooltipDialogs } from '@/components/tooltips/HelpTooltipDialogs.vue';
import { useDomainApi } from '@/module/api/domain';
import { DOMO_EXPIRATION_TTL_DAYS, isModelExpired } from '@/module/api/domo';
import { EntityType } from '@/module/api/domo/five9-entities.interface';
import { ExportStateName, getJobProgressPercentCompletion, useExportApi } from '@/module/api/export';
import { LoadJobStateName, useLoadJobApi } from '@/module/api/load-job';
import { useAuth, userCanUseImportJob } from '@/module/auth';
import router from '@/router';
import { formatDbDate, formatNumber } from '@/utils';
import DomainDialog from '@/views/app/domains/DomainDialog.vue';
import ExportJobSetupDialog, { useExportJobSetupDialog } from '@/views/app/export/ExportJobSetupDialog.vue';
import WorkspaceImportJobList, { useImportJobList } from '@/views/app/import/ImportJobList.vue';
import { computed, defineComponent, reactive, ref, toRefs } from '@vue/composition-api';
import Vue from 'vue';
import { BAD_FIVE9_VERSION_MESSAGE } from '@/lib/constants';

const { assertionContext } = useAuth();

const { add: createAndRunExportJob } = useExportJobSetupDialog();

const {
  isLoading: isDomainLoading,
  selectedItem: selectedDomain,
  refreshItem: refreshDomain,
  getVersions,
  isExportNewerThanLatestJob,
  hasReadPermission,
  hasWritePermission,
} = useDomainApi();

const { open } = useConfirmation();

const { cancelExportJob, runDownloadJobReport, isLoading: isExportLoading, emptyExportModel } = useExportApi();

const { refreshItems: refreshLoadJobs } = useImportJobList();

const { isLoading: isLoadJobLoading } = useLoadJobApi();

const { showRecentJobsHelpTooltipDialog } = useHelpTooltipDialogs();

const emptyState = () => {
  return {
    isCanceling: false,
    domainUuid: '',
    recentJobsHelpTooltipDialog: false,
    showSnackbar: false,
    isMonolith: false,
    mostRecentExtractJob: computed(() => {
      if (selectedDomain?.value?.mostRecentExtractJob?.uuid) {
        return selectedDomain.value.mostRecentExtractJob;
      }
      return emptyExportModel();
    }),
    haveRecentExtractJob: computed(() => {
      return selectedDomain?.value?.mostRecentExtractJob?.uuid;
    }),
  };
};
const state = reactive(emptyState());

const routes = reactive({
  recentExportJob: computed((): string => {
    if (!selectedDomain.value) {
      return '';
    }
    return selectedDomain?.value?.mostRecentExtractJob
      ? `/domain/${selectedDomain?.value?.uuid || state.domainUuid}/job/export/${
          selectedDomain?.value?.mostRecentExtractJob.uuid
        }/browse`
      : '/domain/home';
  }),
});

const domainDialog = ref<any>();

const formattedLastExportDate = computed(() => {
  return formatDbDate(selectedDomain?.value?.mostRecentExtractJob?.createdAt);
});

const getObjectSummaryItems = (): { entityType?: string; count?: number }[] => {
  if (!selectedDomain?.value?.mostRecentExtractJob?.objectSummary?.totalByType) {
    return [];
  }
  const totalByType = selectedDomain?.value?.mostRecentExtractJob?.objectSummary?.totalByType;
  return Object.keys(totalByType).map((entityType: string) => {
    const count = totalByType[entityType as EntityType];
    return { entityType, count: count };
  });
};

const latestExportJobState = computed((): ExportStateName | undefined => {
  return selectedDomain?.value?.mostRecentExtractJob?.state;
});

const extractJobPercentComplete = computed((): number => {
  const mostRecentExtractJob = selectedDomain?.value?.mostRecentExtractJob;
  if (mostRecentExtractJob === undefined) {
    return 0;
  }
  if (
    mostRecentExtractJob.state == ExportStateName.DONE ||
    mostRecentExtractJob.state == ExportStateName.CANCELLED ||
    mostRecentExtractJob.state == ExportStateName.ERRORED
  ) {
    return 100;
  }
  return getJobProgressPercentCompletion(mostRecentExtractJob);
});

const objectSummary = computed(() => {
  if (isDomainLoading.value || selectedDomain?.value?.mostRecentExtractJob === undefined) {
    return {
      headers: [
        {
          text: 'Type',
          value: 'entityType',
          width: 500,
        },
        {
          text: 'Count',
          value: 'count',
          width: 100,
        },
      ],
      items: [],
      total: 0,
    };
  }
  return {
    headers: [
      {
        text: 'Type',
        value: 'entityType',
        width: 500,
      },
      {
        text: 'Count',
        value: 'count',
        width: 100,
      },
    ],
    items: getObjectSummaryItems(),
    total: selectedDomain?.value?.mostRecentExtractJob?.objectSummary?.total,
  };
});

const reset = async () => {
  Object.assign(state, { ...emptyState });
};

const isAnyJobRunning = computed((): boolean => {
  if (selectedDomain?.value?.mostRecentExtractJob?.state === ExportStateName.IN_PROGRESS) {
    return true;
  }
  if (selectedDomain?.value?.mostRecentLoadJob?.state === LoadJobStateName.IN_PROGRESS) {
    return true;
  }
  return false;
});

const initializeData = async (forceRefresh = false) => {
  Vue.$log.debug('Initializing data');
  await refreshDomain(state.domainUuid, forceRefresh);
  const versions = await getVersions(state.domainUuid);
  state.isMonolith = versions && versions.isMonolith ? true : false;
  state.showSnackbar = !state.haveRecentExtractJob;
};

const refreshItems = async () => {
  await initializeData(true);
  refreshLoadJobs();
};

const handleSuccessfulDomainExport = async (): Promise<void> => {
  await refreshItems();
};

const viewDomain = () => {
  domainDialog.value.view(selectedDomain?.value);
};

const createNewImport = async () => {
  router.push({
    name: 'ImportJobImportSetupWizard',
    params: { uuid: selectedDomain?.value?.uuid },
  });
};

const goToDomainObjectBrowserPage = () => {
  router.push({
    name: 'DomainObjectBrowser',
    params: {
      uuid: selectedDomain?.value?.uuid,
      jobUuid: selectedDomain?.value?.mostRecentExtractJob?.uuid,
    },
  });
};

const goToSnapshot = () => {
  router.push({
    name: 'WorkspaceExportJobDetail',
    params: {
      uuid: selectedDomain?.value?.uuid,
      jobUuid: selectedDomain?.value?.mostRecentExtractJob?.uuid,
    },
  });
};

const goToLoadJobListPage = () => {
  router.push({
    name: 'WorkspaceImportJobList',
    params: {
      uuid: selectedDomain?.value?.uuid,
    },
  });
};

const isSnapshotButtonDisabled = computed(() => {
  if (!hasReadPermission(selectedDomain.value)) {
    return true;
  }
  return isAnyJobRunning.value || isExportLoading.value;
});

const isNewJobButtonDisabled = computed(() => {
  if (!hasWritePermission(selectedDomain.value)) {
    return true;
  }
  return isAnyJobRunning.value || !isExportNewerThanLatestJob.value;
});

const runCancelExtractJob = async (): Promise<void> => {
  state.isCanceling = true;
  open({
    title: 'Cancel Snapshot?',
    message: 'Click Yes to cancel the snapshot.',
    options: {
      buttonOk: 'Yes',
      buttonCancel: 'No',
    },
  })
    .then(
      async (confirmed: boolean): Promise<void> => {
        if (confirmed) {
          await cancelExportJob(selectedDomain.value?.mostRecentExtractJob?.uuid);
          await refreshItems();
        }
      },
    )
    .finally(() => {
      state.isCanceling = false;
    });
};

export default defineComponent({
  name: 'DomainWorkspace',
  components: {
    RefreshButton,
    Confirm,
    DomainDialog,
    JobStatusIcon,
    ExportJobSetupDialog,
    DomainWorkspaceToolbar,
    WorkspaceImportJobList,
    HelpTooltipDialogs,
    JobDurationText,
  },
  props: {
    uuid: {
      type: String,
    },
  },
  setup(props) {
    state.domainUuid = props.uuid || '';
    initializeData();

    return {
      ...toRefs(state),
      isLoading: computed(() => {
        return isDomainLoading.value || isExportLoading.value || isLoadJobLoading.value || state.isCanceling;
      }),
      assertionContext,
      routes,
      isLoadJobLoading,
      domainDialog,
      viewDomain,
      objectSummary,
      formattedLastExportDate,
      reset,
      formatDbDate,
      formatNumber,
      refreshItems,
      selectedDomain,
      runCancelExtractJob,
      LoadJobStateName,
      createNewImport,
      createAndRunExportJob,
      isExportLoading,
      isAnyJobRunning,
      goToDomainObjectBrowserPage,
      goToLoadJobListPage,
      goToSnapshot,
      showRecentJobsHelpTooltipDialog,
      handleSuccessfulDomainExport,
      userCanUseImportJob,
      extractJobPercentComplete,
      latestExportJobState,
      ExportStateName,
      isExportNewerThanLatestJob,
      isSnapshotButtonDisabled,
      isNewJobButtonDisabled,
      runDownloadJobReport,
      isModelExpired,
      DOMO_EXPIRATION_TTL_DAYS,
      backLink: '/domain/home',
      BAD_FIVE9_VERSION_MESSAGE,
      menu: () => {
        return [
          // TODO: { title: 'Download Object List', link: '' },
          {
            title: 'Snapshots',
            action: () => {
              router.push({
                name: 'WorkspaceExportJobList',
                params: { uuid: state.domainUuid },
              });
            },
            icon: 'mdi-database',
            iconColor: '',
            description: 'View Snapshots',
            show: true,
            disabled: false,
          },
          {
            title: 'Jobs',
            action: () => {
              router.push({
                name: 'WorkspaceImportJobList',
                params: { uuid: state.domainUuid },
              });
            },
            icon: 'mdi-rocket',
            iconColor: '',
            description: 'View Jobs',
            show: true,
            disabled: false,
          },
          {
            title: 'New Snapshot',
            action: () => createAndRunExportJob(selectedDomain.value),
            icon: 'mdi-database-arrow-down-outline',
            iconColor: 'green darken-3',
            description: 'Take a new Snapshot of the Domain',
            show: true,
            disabled: isSnapshotButtonDisabled.value,
          },
          {
            title: 'Import to Domain',
            action: () => {
              router.push({
                name: 'ImportJobImportSetupWizard',
                params: { uuid: state.domainUuid },
              });
            },
            icon: 'mdi-import',
            iconColor: 'red lighten-2',
            description: '',
            show: true,
            disabled: isNewJobButtonDisabled.value,
          },
          {
            title: 'Restore Domain',
            action: () => {
              router.push({
                name: 'ImportJobRestoreSetupWizard',
                params: { uuid: state.domainUuid },
              });
            },
            icon: 'mdi-restore',
            iconColor: 'blue darken-3',
            description: '',
            show: true,
            disabled: isNewJobButtonDisabled.value,
          },
          {
            title: 'Reset Domain',
            action: () => {
              router.push({
                name: 'ImportJobResetSetupWizard',
                params: { uuid: state.domainUuid },
              });
            },
            icon: 'mdi-alert-outline',
            iconColor: 'orange darken-1',
            description: '',
            show: true,
            disabled: isNewJobButtonDisabled.value,
          },
        ];
      },
    };
  },
});

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("domain-workspace-toolbar", {
        scopedSlots: _vm._u([
          {
            key: "back",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  { attrs: { icon: "", router: "", to: _vm.backLink } },
                  [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c("refresh-button", {
                  attrs: { refreshFunction: _vm.refreshItems }
                }),
                _c(
                  "v-btn",
                  {
                    staticClass: "mx-2",
                    attrs: {
                      small: "",
                      disabled: _vm.isSnapshotButtonDisabled,
                      title: "Re-export data from this Domain"
                    },
                    on: {
                      click: function($event) {
                        return _vm.createAndRunExportJob(_vm.selectedDomain)
                      }
                    }
                  },
                  [
                    _vm._v(" New Snapshot "),
                    _vm.isExportLoading
                      ? _c("v-progress-circular", {
                          staticClass: "ml-2",
                          attrs: { indeterminate: "", size: "20", width: "5" }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _vm.userCanUseImportJob(_vm.assertionContext.roles)
                  ? _c(
                      "v-btn",
                      {
                        staticClass: "mx-2",
                        attrs: {
                          small: "",
                          disabled: _vm.isNewJobButtonDisabled,
                          title:
                            "Import this Domain's configuration into another domain"
                        },
                        on: { click: _vm.createNewImport }
                      },
                      [_vm._v(" New Import ")]
                    )
                  : _vm._e(),
                _c(
                  "v-menu",
                  {
                    attrs: { "offset-y": "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  { attrs: { icon: "" } },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [_c("v-icon", [_vm._v("mdi-dots-vertical")])],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c(
                      "v-list",
                      { staticClass: "text-center" },
                      _vm._l(_vm.menu(), function(menuItem, index) {
                        return _c(
                          "v-list-item",
                          {
                            key: index,
                            attrs: { disabled: menuItem.disabled },
                            on: { click: menuItem.action }
                          },
                          [
                            _c(
                              "v-list-item-icon",
                              [
                                _c("v-icon", {
                                  attrs: { color: menuItem.iconColor },
                                  domProps: {
                                    textContent: _vm._s(menuItem.icon)
                                  }
                                })
                              ],
                              1
                            ),
                            _c("v-list-item-content", [
                              _vm._v(_vm._s(menuItem.title))
                            ])
                          ],
                          1
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "v-card",
        { staticClass: "mb-4", attrs: { loading: _vm.isLoading } },
        [
          _c(
            "v-card-title",
            [
              _c("job-status-icon", {
                attrs: {
                  job: _vm.mostRecentExtractJob,
                  suffix: "Object Summary"
                }
              })
            ],
            1
          ),
          _vm.formattedLastExportDate
            ? _c(
                "v-card-subtitle",
                [
                  _vm._v(
                    "Last Snapshot at " +
                      _vm._s(
                        _vm.formatDbDate(_vm.mostRecentExtractJob.updatedAt)
                      ) +
                      " "
                  ),
                  _c("job-duration-text", {
                    attrs: { job: _vm.mostRecentExtractJob }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-card-text",
            [
              _vm.isMonolith
                ? _c(
                    "v-alert",
                    {
                      staticClass: "elevation-2",
                      attrs: {
                        type: "error",
                        "colored-border": "",
                        border: "top"
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.BAD_FIVE9_VERSION_MESSAGE) + " ")]
                  )
                : _vm._e(),
              !_vm.mostRecentExtractJob.uuid
                ? _c(
                    "v-alert",
                    {
                      staticClass: "elevation-2",
                      attrs: {
                        type: "warning",
                        "colored-border": "",
                        border: "top"
                      }
                    },
                    [
                      _vm._v(
                        ' There are no objects for this domain. Click "New Snapshot" to get started. '
                      )
                    ]
                  )
                : _vm.latestExportJobState === _vm.ExportStateName.IN_PROGRESS
                ? _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-avatar",
                        { attrs: { tile: "", size: "100" } },
                        [
                          _c(
                            "v-progress-circular",
                            {
                              attrs: {
                                rotate: 270,
                                size: 100,
                                width: 15,
                                value: _vm.extractJobPercentComplete,
                                color: "blue darken-3"
                              }
                            },
                            [
                              _c("span", { staticClass: "title" }, [
                                _vm._v(
                                  _vm._s(_vm.extractJobPercentComplete) + "%"
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "title mb-1" },
                            [_vm._v(" Snapshot in progress...")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm.latestExportJobState === _vm.ExportStateName.ERRORED
                ? _c(
                    "v-alert",
                    {
                      staticClass: "elevation-2",
                      attrs: {
                        type: "error",
                        "colored-border": "",
                        border: "top"
                      }
                    },
                    [
                      _vm._v(" The latest snapshot failed, "),
                      _c(
                        "a",
                        {
                          attrs: {
                            disabled: _vm.isModelExpired(
                              _vm.mostRecentExtractJob
                            )
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.goToSnapshot.apply(null, arguments)
                            }
                          }
                        },
                        [_vm._v("View Snapshot")]
                      ),
                      _vm._v(" for more information. ")
                    ]
                  )
                : _vm.latestExportJobState === _vm.ExportStateName.CANCELLED
                ? _c(
                    "v-alert",
                    {
                      staticClass: "elevation-2",
                      attrs: {
                        type: "warning",
                        "colored-border": "",
                        border: "top"
                      }
                    },
                    [_vm._v(" The latest snapshot was cancelled. ")]
                  )
                : !_vm.isExportNewerThanLatestJob && !_vm.isAnyJobRunning
                ? _c(
                    "v-alert",
                    {
                      staticClass: "elevation-2",
                      attrs: {
                        type: "warning",
                        "colored-border": "",
                        border: "top"
                      }
                    },
                    [
                      _vm._v(
                        " A job has been run since the last snapshot. Create a new snapshot before running another job. "
                      )
                    ]
                  )
                : _c(
                    "v-data-table",
                    {
                      attrs: {
                        headers: _vm.objectSummary.headers,
                        items: _vm.objectSummary.items,
                        "items-per-page": 8,
                        "sort-by": "entityType",
                        dense: ""
                      }
                    },
                    [
                      _c("template", { slot: "body.append" }, [
                        _c("tr", { staticClass: "font-weight-bold" }, [
                          _c("td", [_vm._v("TOTAL")]),
                          _c("td", [_vm._v(_vm._s(_vm.objectSummary.total))])
                        ])
                      ])
                    ],
                    2
                  ),
              _vm.mostRecentExtractJob.uuid &&
              _vm.isModelExpired(_vm.mostRecentExtractJob)
                ? _c(
                    "div",
                    [
                      _c(
                        "v-alert",
                        {
                          staticClass: "elevation-2",
                          attrs: {
                            type: "error",
                            "colored-border": "",
                            border: "top"
                          }
                        },
                        [
                          _c("b", [_vm._v("EXPIRED")]),
                          _vm._v(
                            ": This Snapshot is no longer available because it is older than " +
                              _vm._s(_vm.DOMO_EXPIRATION_TTL_DAYS) +
                              " days. "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "accent-4",
                    plain: "",
                    small: "",
                    disabled:
                      !_vm.haveRecentExtractJob ||
                      _vm.latestExportJobState ===
                        _vm.ExportStateName.IN_PROGRESS ||
                      _vm.isModelExpired(_vm.mostRecentExtractJob)
                  },
                  on: { click: _vm.goToSnapshot }
                },
                [
                  _c("v-icon", [_vm._v("mdi-arrow-right")]),
                  _vm._v(" View Snapshot ")
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "accent-4",
                    plain: "",
                    small: "",
                    disabled:
                      !_vm.haveRecentExtractJob ||
                      _vm.latestExportJobState ===
                        _vm.ExportStateName.IN_PROGRESS ||
                      _vm.isModelExpired(_vm.mostRecentExtractJob)
                  },
                  on: { click: _vm.goToDomainObjectBrowserPage }
                },
                [
                  _c("v-icon", [_vm._v("mdi-magnify")]),
                  _vm._v(" Browse Objects ")
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "accent-4",
                    title: "Download object report",
                    plain: "",
                    small: "",
                    disabled:
                      _vm.isLoading ||
                      !_vm.haveRecentExtractJob ||
                      _vm.latestExportJobState ===
                        _vm.ExportStateName.IN_PROGRESS ||
                      _vm.isModelExpired(_vm.mostRecentExtractJob)
                  },
                  on: {
                    click: function($event) {
                      return _vm.runDownloadJobReport(
                        _vm.mostRecentExtractJob.uuid
                      )
                    }
                  }
                },
                [
                  _c("v-icon", [_vm._v("mdi-file-excel")]),
                  _vm._v(" Download Object Report ")
                ],
                1
              ),
              _vm.latestExportJobState === _vm.ExportStateName.IN_PROGRESS
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "error",
                        text: "",
                        small: "",
                        disabled:
                          _vm.isCanceling ||
                          _vm.isModelExpired(_vm.mostRecentExtractJob)
                      },
                      on: { click: _vm.runCancelExtractJob }
                    },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v("mdi-close-circle")
                      ]),
                      _vm._v(" Cancel ")
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "mb-4", attrs: { loading: _vm.isLoading } },
        [
          _c(
            "v-card-title",
            [
              _vm._v("Recent Jobs "),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", color: "grey" },
                  on: { click: _vm.showRecentJobsHelpTooltipDialog }
                },
                [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v("mdi-information-outline")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("v-card-subtitle", [_vm._v("Most Recent 10")]),
          _c(
            "v-card-text",
            [
              _vm.selectedDomain.uuid
                ? _c("workspace-import-job-list", {
                    attrs: { simpleTable: "", uuid: _vm.selectedDomain.uuid }
                  })
                : _vm._e(),
              _c(
                "v-btn",
                {
                  attrs: { color: "accent-4", plain: "", small: "" },
                  on: { click: _vm.goToLoadJobListPage }
                },
                [
                  _c("v-icon", [_vm._v("mdi-arrow-right")]),
                  _vm._v(" View All Jobs ")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("export-job-setup-dialog", {
        on: { "dialog:export:saved": _vm.handleSuccessfulDomainExport }
      }),
      _c("help-tooltip-dialogs"),
      _c("confirm"),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: 15000, transition: "scale-transition" },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function(ref) {
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._b(
                      {
                        attrs: { color: "success lighten-2", text: "" },
                        on: {
                          click: function($event) {
                            _vm.showSnackbar = false
                          }
                        }
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [_vm._v(" Close ")]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.showSnackbar,
            callback: function($$v) {
              _vm.showSnackbar = $$v
            },
            expression: "showSnackbar"
          }
        },
        [
          _vm._v(
            " This domain doesn't have any data yet. Click 'New Snapshot' to retrieve the data from this domain. After that, you can select 'New Import' to import some data. "
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }